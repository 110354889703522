<template>
  <header class="header" id="header" itemscope itemtype="https://schema.org/WPHeader">
    <div class="header__middle">
      <meta itemprop="name" content="Dr. Max" />
      <meta itemprop="url" :content="eshopUrl" />
      <a
        v-if="isDrogeria"
        :href="eshopUrl"
        class="header__logo default mx-2"
        itemprop="image logo"
        itemscope
        itemtype="https://schema.org/ImageObject"
      >
        <img
          itemprop="url"
          src="@/assets/images/logo-drogeria.svg"
          alt="eDrogeria"
          width="147"
          height="50"
        />
      </a>
      <a
        v-else
        :href="eshopUrl"
        class="header__logo default mx-2"
        itemprop="image logo"
        itemscope
        itemtype="https://schema.org/ImageObject"
      >
        <img itemprop="url" src="@/assets/images/logo.svg" alt="Dr. Max" width="147" height="50" />
        <p v-if="isApteka" class="apteka mx-2 h1 m-0">Apteka</p>
      </a>
      <p class="h2 font-weight-light m-0 d-none d-sm-block header__slogan">
        {{ $t('title') }}
      </p>

      <a
        v-if="robotEnabled"
        href="#robot"
        class="col d-block d-lg-none text-end me-2"
        title="Robot Tom"
      >
        <img src="@/assets/images/robot-icon.svg" alt="Robot Tom" width="50" height="50" />
      </a>
    </div>
  </header>
</template>

<script>
import { ESHOP_URL, CURRENT_MUTATION } from '@common/config'

export default {
  props: {
    robotEnabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isApteka() {
      return CURRENT_MUTATION === 'pl-apteka'
    },

    isDrogeria() {
      return CURRENT_MUTATION === 'pl-drogeria'
    },

    eshopUrl() {
      return ESHOP_URL
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .header__slogan {
    color: #58595a;
  }
}
</style>
